import { richTextSerializerWithOptions } from '@ee-monorepo/prismic/shared/serializers';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import {
  ImageWrapper,
  TooltipWithPopper,
} from '@ee-monorepo/prismic/shared/ui';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { ApplyButton } from '@ee-monorepo/shared/ui/apply-button';
import { Button } from '@ee-monorepo/shared/ui/button';
import { useModernNavBarContext } from '@ee-monorepo/shared/utilities/hooks';
import { Industry } from '@ee-monorepo/shared/utilities/types';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { CapsuleGraphic } from './capsule-graphic';
import { PrismicRichText } from '@prismicio/react';
import s from './hero.module.scss';
import * as prismicH from '@prismicio/helpers';
import CheckIcon from '@mui/icons-material/Check';
import { getColor } from '@ee-monorepo/shared/utilities/functions';
import { usePrequalExperiment } from '@ee-monorepo/growth-experiments';
import HeroImage from './hero-image';
import { getStyle } from './hero.styles';
import sanitizeHTML from 'sanitize-html';
import { replaceRichTextPlaceholder } from '@ee-monorepo/prismic/shared/utilities';

export interface HeroProps extends PrismicFields.SliceBase {
  slice: {
    primary: {
      hero_title?: PrismicFields.RichTextField;
      hero_subtitle?: PrismicFields.RichTextField;
      hero_title_color?: PrismicFields.Select;
      hero_subtitle_color?: PrismicFields.Select;
      show_header_disclaimer: boolean;
      header_disclaimer_text?: PrismicFields.RichTextField;
      body_text: PrismicFields.RichTextField;
      prequal_body_text?: PrismicFields.RichTextField;
      show_body_disclaimer: boolean;
      body_disclaimer_text?: PrismicFields.RichTextField;
      store_locator_button_label: PrismicFields.KeyTextField;
      apply_button_label: PrismicFields.KeyTextField;
      reviews_label: PrismicFields.KeyTextField;
      reviews_logo: PrismicFields.Image;
      reviews_rate: PrismicFields.Number;
      hero_image?: PrismicFields.Image;
      text_below_action_buttons?: PrismicFields.RichTextField;
      slice_background_color?: PrismicFields.Select;
      is_center_image?: boolean;
      prequal_hero_image?: PrismicFields.Image;
      prequal_bullet_text_1?: PrismicFields.RichTextField;
      prequal_bullet_text_2?: PrismicFields.RichTextField;
      prequal_bullet_text_3?: PrismicFields.RichTextField;
      prequal_bullet_disclaimer_text_1?: PrismicFields.RichTextField;
      prequal_bullet_disclaimer_text_2?: PrismicFields.RichTextField;
      prequal_bullet_disclaimer_text_3?: PrismicFields.RichTextField;
      button_fixed_position_on_mobile?: boolean;
    };
    items?: {
      hero_image: PrismicFields.Image;
      industry: Industry;
    }[];
    variation?:
      | 'default-slice'
      | 'heroStaticImage'
      | 'heroPrequal'
      | 'heroStackVariant';
  };
}

export function Hero({
  index,
  slice: {
    primary: {
      hero_title,
      hero_subtitle,
      hero_title_color,
      hero_subtitle_color,
      show_body_disclaimer,
      body_text,
      prequal_body_text,
      body_disclaimer_text,
      apply_button_label,
      store_locator_button_label,
      reviews_rate,
      reviews_label,
      reviews_logo,
      hero_image,
      text_below_action_buttons,
      slice_background_color,
      is_center_image,
      prequal_hero_image,
      prequal_bullet_text_1,
      prequal_bullet_text_2,
      prequal_bullet_text_3,
      prequal_bullet_disclaimer_text_1,
      prequal_bullet_disclaimer_text_2,
      prequal_bullet_disclaimer_text_3,
      button_fixed_position_on_mobile,
    },
    items,
    variation,
  },
}: HeroProps) {
  const t = useTranslations();
  const { setModernNavBarEnabled } = useModernNavBarContext();
  const { tracking } = useTrackingContext();
  const [industryIndex, setIndustryIndex] = useState(0);
  const industry = items?.length > 0 ? items[industryIndex].industry : null;
  const { isPrequal } = usePrequalExperiment();
  const styles = getStyle(variation, { button_fixed_position_on_mobile });
  const sanitizeOptions = {
    allowedAttributes: {
      td: ['rowspan', 'colspan'],
      '*': ['class'],
    },
  };

  useEffect(() => {
    setModernNavBarEnabled(true);
    document.body.classList.add('has-mobile-hero-btn');
    return () => {
      setModernNavBarEnabled(false);
      document.body.classList.remove('has-mobile-hero-btn');
    };
  }, [setModernNavBarEnabled]);

  useEffect(() => {
    let interval: number = null;
    const onChange = () => {
      const lastIndex = items.length - 1;
      if (industryIndex < lastIndex) {
        return setIndustryIndex(industryIndex + 1);
      }
      setIndustryIndex(0);
    };
    if (items?.length > 1) {
      interval = window.setInterval(onChange, 5000);
    }
    return () => {
      window.clearInterval(interval);
    };
  }, [items, industryIndex, setIndustryIndex]);

  return (
    <div
      data-testid="hero"
      data-variant={variation}
      data-slice-index={index}
      className={cn(
        'relative',
        s.slice,
        slice_background_color
          ? `${getColor(slice_background_color).backgroundColorClass}`
          : null
      )}
    >
      {isPrequal && variation === 'heroPrequal' && (
        <div className={cn(styles.prequalImageParent)}>
          <ImageWrapper
            className={cn('lg:hidden')}
            src={prequal_hero_image?.mobile?.url}
            alt={prequal_hero_image?.mobile?.alt}
            width={prequal_hero_image?.mobile?.dimensions?.width}
            height={prequal_hero_image?.mobile?.dimensions?.height}
            data-testid={'heroStaticImageMobile'}
          />
        </div>
      )}
      <div className={styles.heroContentParent}>
        {/* hero-content */}
        <div data-testid="hero-content" className={styles.heroContent}>
          {variation === 'default-slice' ? (
            <h1
              className="mb-4 headingXL md:displayFont text-SnapDarkBlue"
              data-testid="heroTitle"
            >
              {t.rich('shared.hero_header', {
                block: (children) => (
                  <span className="block md:inline-block">{children}</span>
                ),
                industry: () => (
                  <span
                    className={cn(
                      'text-SnapGreen px-4 md:inline-block',
                      s.industry
                    )}
                  >
                    {industry && t(`shared.industries.${industry}.label`)}
                  </span>
                ),
              })}
              <span className="block">{t('shared.hero_header_sub_line')}</span>
            </h1>
          ) : (
            <h1
              className={styles.heroTitle}
              data-testid="heroTitleStaticImageVariation"
            >
              <div
                className={cn(
                  hero_title_color
                    ? getColor(hero_title_color).textColorClass
                    : 'text-SnapDarkBlue'
                )}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(
                    `${replaceRichTextPlaceholder(hero_title, []).map(
                      (c) => c.text
                    )}`,
                    sanitizeOptions
                  ),
                }}
              ></div>
              <div
                className={cn(
                  'px-3',
                  hero_subtitle_color
                    ? getColor(hero_subtitle_color).textColorClass
                    : 'text-SnapGreen'
                )}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(
                    `${replaceRichTextPlaceholder(hero_subtitle, []).map(
                      (c) => c.text
                    )}`,
                    sanitizeOptions
                  ),
                }}
              ></div>
            </h1>
          )}
          {/* body text */}
          <div
            data-testid="hero-body-content"
            className={styles.heroBodyContent}
          >
            {show_body_disclaimer ? (
              <TooltipWithPopper
                mainContent={
                  isPrequal && variation === 'heroPrequal'
                    ? prequal_body_text
                    : body_text
                }
                mainContentSerializer={richTextSerializerWithOptions()}
                popperContent={body_disclaimer_text}
                elementType="heading5"
                mainContentColor="text-SnapGrey500"
              />
            ) : (
              <div
                data-testid="content-block-body-without-popper"
                className="text-SnapGrey500"
              >
                <PrismicRichText
                  field={
                    isPrequal && variation === 'heroPrequal'
                      ? prequal_body_text
                      : body_text
                  }
                  components={richTextSerializerWithOptions()}
                />
              </div>
            )}
          </div>
          {/* bullets */}
          {isPrequal && variation === 'heroPrequal' && (
            <div className={cn('mt-8')}>
              <div
                className={cn(s.container, styles.prequalBulletParent)}
                data-testid="prequal-bullet-text-1"
              >
                <CheckIcon color="primary" className={cn('mx-2')} />
                <TooltipWithPopper
                  mainContent={prequal_bullet_text_1}
                  mainContentSerializer={richTextSerializerWithOptions()}
                  popperContent={prequal_bullet_disclaimer_text_1}
                  elementType="heading5"
                  mainContentColor="text-SnapGrey500"
                />
              </div>
              <div
                className={cn(s.container, styles.prequalBulletParent)}
                data-testid="prequal-bullet-text-2"
              >
                <CheckIcon color="primary" className={cn('mx-2')} />
                <TooltipWithPopper
                  mainContent={prequal_bullet_text_2}
                  mainContentSerializer={richTextSerializerWithOptions()}
                  popperContent={prequal_bullet_disclaimer_text_2}
                  elementType="heading5"
                  mainContentColor="text-SnapGrey500"
                />
              </div>
              <div
                className={cn(s.container, styles.prequalBulletParent)}
                data-testid="prequal-bullet-text-3"
              >
                <CheckIcon color="primary" className={cn('mx-2')} />
                <TooltipWithPopper
                  mainContent={prequal_bullet_text_3}
                  mainContentSerializer={richTextSerializerWithOptions()}
                  popperContent={prequal_bullet_disclaimer_text_3}
                  elementType="heading5"
                  mainContentColor="text-SnapGrey500"
                />
              </div>
            </div>
          )}
          {/* buttons */}
          <div className={styles.buttonParent}>
            {apply_button_label ? (
              <ApplyButton
                onClick={() => {
                  tracking.logClickEvent({
                    click_type: 'cta',
                    placement: 'hero',
                    click_value: 'get started',
                  });
                }}
                className={styles.applyButton}
                data-testid="applyButton"
              >
                {apply_button_label}
              </ApplyButton>
            ) : null}
            {store_locator_button_label ? (
              <Button
                href={`/find-stores?industry=${industry}`}
                variant="secondary"
              >
                {store_locator_button_label}
              </Button>
            ) : null}
          </div>
          {text_below_action_buttons && !button_fixed_position_on_mobile ? (
            <div
              className={styles.textBelowActionButtons}
              data-testid="text_below_action_buttons"
            >
              <PrismicRichText
                field={text_below_action_buttons}
                components={richTextSerializerWithOptions()}
              />
            </div>
          ) : null}

          <div
            className={cn(
              styles.applyButtonMobileParent,
              s.applyButtonMobileContainer,
              slice_background_color
                ? `${getColor(slice_background_color).backgroundColorClass}`
                : `bg-SnapSand`
            )}
          >
            {apply_button_label ? (
              <ApplyButton
                onClick={() => {
                  tracking.logClickEvent({
                    click_type: 'cta',
                    placement: 'bottom anchor',
                    click_value: 'get started',
                  });
                }}
                data-testid="applyMobileButton"
              >
                {apply_button_label}
              </ApplyButton>
            ) : null}
            {text_below_action_buttons &&
            variation === 'heroStackVariant' &&
            button_fixed_position_on_mobile ? (
              <div
                className={styles.textBelowActionButtons}
                data-testid="text_below_action_buttons"
              >
                <PrismicRichText
                  field={text_below_action_buttons}
                  components={richTextSerializerWithOptions()}
                />
              </div>
            ) : null}
          </div>
        </div>

        {/* hero image */}
        <HeroImage
          variation={variation}
          items={items}
          industryIndex={industryIndex}
          isPrequal={isPrequal}
          prequal_hero_image={prequal_hero_image}
          is_center_image={is_center_image}
          hero_image={hero_image}
        />

        {variation === 'default-slice' ? (
          <>
            <CapsuleGraphic
              size={'normal'}
              className={cn(styles.capsuleGraphic, s.heroImageGraphic)}
              data-testId="graphic"
            />
            <CapsuleGraphic
              size={'small'}
              className={cn(styles.capsuleGraphicMobile, s.heroImageGraphic)}
              data-testId="graphic mobile"
            />
          </>
        ) : null}
      </div>
    </div>
  );
}
